<template>
  <div class="grid surveyform-wrap">
    <div class="col-12">
      <div class="card">
        <ProgressSpinner v-if="false === ready" style="width: 50px; height: 50px" stroke-width="8" fill="#EEEEEE" />
        <div v-if="true === ready" class="survey-content" style="width: 100%">
          <h2>{{ survey.name }}</h2>
          <div class="p-fluid formgrid grid">
            <div v-for="group of survey.groups" :key="group" class="field col-12">
              <h4>{{ group.name }}</h4>
              <div v-for="question of group.questions" :key="question" class="field col-12">
                <label v-if="question.response != null">{{ question.name }}</label>

                <!-- Short Question -->
                <InputText v-if="question.type == 'Short Question' && question.response != null" v-model="question.response" type="text" disabled />

                <!-- Long Question -->

                <Textarea v-if="question.type == 'Long Question' && question.response != null" v-model="question.response" rows="4" disabled />

                <!-- Single Choice Question -->
                <div v-if="question.type == 'Single Choice Question' && question.response != null">
                  <div v-for="choice of question.answer.options" :key="choice" class="field-radiobutton col-6">
                    <RadioButton v-model="question.response" :value="choice.choice" :disabled="true" />
                    <label>{{ choice.choice }}</label>
                  </div>
                </div>

                <!-- Multiple Choice Question -->
                <div v-if="question.type == 'Multiple Choice Question' && question.response != null">
                  <MultiSelect v-model="question.response" :options="question.answer.options" placeholder="Select Choice" disabled />
                </div>
                <div v-if="(question.response == null || question.response?.length == 0) && submit" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

//
<script>
import surveyService from "../../service/survey";
import respondentService from "../../service/respondent";

export default {
  data() {
    return {
      surveyid: null,
      responseid: this.$route.params.responseid,
      survey: {},
      response: {},
      ready: false,
      answer: null,
      validate: {},
    };
  },

  mounted() {
    respondentService.getRespondent({ responseid: this.responseid }).then((result) => {
      this.response = result[0];
      this.surveyid = result[0].survey.id;
      this.getSurvey();
    });
  },

  methods: {
    getSurvey() {
      let obj = { surveyid: this.surveyid };
      surveyService.getSurvey(obj).then((result) => {
        this.survey = { id: result[0].id, name: result[0].name };
        this.survey.groups = [];

        result[0].groups.forEach((group) => {
          let obj = { id: group.id, name: group.name, questions: [] };
          this.survey.groups.push(obj);
        });

        result[0].questions.forEach((question) => {
          console.log(question);
          if (question.type == "Multiple Choice Question") {
            let choices = [];
            question.answer.options?.forEach((choice) => {
              choices.push(choice.choice);
            });
            question.answer.options = choices;
          }
          question.response = null;
          console.log(question);

          this.response.answer.forEach((answer) => {
            if (answer.question == question.id) {
              question.response = answer.answer;
            }
          });
          console.log(this.response);

          this.survey.groups.forEach((group) => {
            if (group.id == question.group) {
              group.questions.push(question);
            }
          });
        });

        this.survey.groups.sort((a, b) => {
          return a.sequence - b.sequence;
        });
        this.survey.groups.forEach((group) => {
          group.questions.sort((a, b) => {
            return a.sequence - b.sequence;
          });
        });
        console.log(this.survey);

        this.ready = true;
      });
    },
  },
};
</script>

//
<style scoped lang="scss">
.surveyform-wrap {
  .col-12 {
    ::v-deep(.card) {
      display: flex;
      align-items: center;
      flex-direction: column;
      min-height: 35rem;
      justify-content: center;
      .link {
        cursor: pointer;
      }
      .survey-content {
        width: 100% !important;
        .field {
          .p-invalid-label {
            color: red;
          }

          .p-inputtextarea,
          .p-multiselect,
          .p-inputtext {
            &.invalid {
              border-color: red;
            }
          }

          .field-radiobutton {
            .p-radiobutton {
              &.invalid {
                .p-radiobutton-box {
                  border-color: red;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
